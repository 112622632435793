.app {
	height: 100vh;
}

.login__logo {
	width: 150px;
}

.nav-icon {
	width: 20px;
	height: 20px;
}

.sidebar__link {
	color: gray !important;
	display: block;
	text-decoration: none !important;
	font-weight: 400;
	transition: all 0.2s;

	&:hover {
		color: black !important;
	}

	&.active {
		color: black !important;
		font-weight: 600;
	}
}

@media print {
	.text__right {
		text-align: right !important;
	}
}
